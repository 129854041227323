import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl; // This is the key part

Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {}; // Even if empty, exporting something is good practice
