/* eslint-disable no-console */
import store from "@/state/store";

export default [
	{
		path: "/",
		name: "default",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/dashboards/default"),
	},
	//   {
	//     path: '/admin/default',
	//     name: 'AdminDashboard',
	//     meta: {
	//       authRequired: true,
	//     },
	//     component: () => import('./views/admin/default'),
	//     beforeEnter: (to, from, next) => {
	//       var user = store.state.currentUser;
	//       var roles = store.state.roles;

	//       if (user && roles && roles.includes('admin')) {
	//         next();
	//       } else {
	//         next({ name: '404' })
	//       }
	//     }
	//   },
	{
		path: "/admin/users",
		name: "AdminUsers",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/users"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("AdminUsers"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/admins",
		name: "AdminAdmins",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/admins"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("AdminAdmins"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/setup-users",
		name: "SetupUsers",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/setup-users"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("SetupUsers"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/archived",
		name: "Archived",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/archived"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("AdminUsers"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/notifications",
		name: "Notifications",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/notifications"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("AdminUsers"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/facility",
		name: "Facility",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/facility"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Facility"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/invoices",
		name: "Invoices",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/invoices"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Invoices"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/timesheets",
		name: "Timesheets",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/timesheets"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Invoices"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/expenses",
		name: "Expenses",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/expenses"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Invoices"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/rewards",
		name: "rewards",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/rewards"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Invoices"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/referrals",
		name: "referrals",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/referrals"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Invoices"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/credentialing",
		name: "Credentialing",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/credentialing"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Credentialing"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/job-status",
		name: "JobStatus",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/job-status"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("JobStatus"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/job-status-closed",
		name: "JobStatusClosed",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/job-status-closed"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("JobStatus"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/jobs",
		name: "Jobs",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/jobs"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Jobs"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/jobs-closed",
		name: "JobsClosed",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/jobs-closed"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Jobs"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/journal",
		name: "Journal",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/journal"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Journal"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/checkins",
		name: "CheckIns",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/checkins"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Jobs"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/reporting",
		name: "Reporting",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/reporting"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Reporting"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/accounting-reporting",
		name: "AccountingReporting",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/accounting-reporting"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Reporting"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/admin/checkin-map",
		name: "CheckInMap",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/admin/checkin-map"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var roles = store.state.roles;

			if (
				user &&
				roles &&
				(roles.includes("admin") || access.includes("Reporting"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/overall",
		name: "OverallDashboard",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/overall/default.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "health professional") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/overall/jobs",
		name: "OverallJobs",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/overall/jobs.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "health professional") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/overall/jobs-closed",
		name: "OverallJobsClosed",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/overall/jobs-closed.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "health professional") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/overall/notifications",
		name: "OverallNotifications",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/overall/notifications.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "health professional") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/overall/timesheets",
		name: "OverallTimesheets",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/overall/timesheets.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "health professional") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility",
		name: "FacilityDashboard",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/default.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (
				user &&
				(type === "facility admin" || type === "facility timesheet approver")
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/approve",
		name: "FacilityApprove",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/approve.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (
				user &&
				(type === "facility admin" || type === "facility timesheet approver")
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/details",
		name: "FacilityDetails",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/details.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/jobsclosed",
		name: "FacilityJobsClosed",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/jobs-closed.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/accounting",
		name: "FacilityAccounting",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/accounting.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/checkin-map",
		name: "FacilityCheckInMap",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/checkin-map.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/jobstatusopen",
		name: "FacilityJobsInProgress",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/job-status-open.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/jobstatusclosed",
		name: "FacilityJobsCompleted",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/job-status-closed.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/jobsopen",
		name: "FacilityJobsOpen",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/jobs-open.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (user && type === "facility admin") {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/checkins",
		name: "FacilityCheckIns",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/checkins.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (
				user &&
				(type === "facility admin" || type === "facility timesheet approver")
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/facility/notifications",
		name: "FacilityNotifications",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/facility/notifications.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			//var permissions = store.state.currentUser.permissions || {};
			//var access = permissions.access || [];
			var type = user.type || "";
			//var roles = store.state.roles || [];

			if (
				user &&
				(type === "facility admin" || type === "facility timesheet approver")
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile",
		name: "BasicInformation",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/basic.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("BasicInformation"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/extended",
		name: "ExtendedProfile",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/extended.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("ExtendedProfile"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/academic",
		name: "AcademicInformation",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/academic.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("AcademicInformation"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/medical",
		name: "Medical",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/medical.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("Medical"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/tb",
		name: "TbQuestionnaire",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/tb.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("TbQuestionnaire"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/certification",
		name: "Certifications",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/certification.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("Certifications"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/checklist",
		name: "ClinicalChecklist",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/checklist.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("ClinicalChecklist"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/jobdescriptions",
		name: "JobDescriptions",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/jobdescriptions.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("JobDescriptions"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/employment",
		name: "Employment",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/employment.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("Employment"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/form",
		name: "FormDocusign",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/form.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("FormDocusign"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/license",
		name: "Licenses",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/license.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("Licenses"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/profile/reference",
		name: "References",
		meta: {
			authRequired: true,
		},
		component: () => import("./views/profile/reference.vue"),
		beforeEnter: (to, from, next) => {
			var user = store.state.currentUser;
			var permissions = store.state.currentUser.permissions || {};
			var access = permissions.access || [];
			var type = user.type || "";
			var roles = store.state.roles || [];

			if (
				user &&
				(type === "health professional" ||
					roles.includes("admin") ||
					access.includes("References"))
			) {
				next();
			} else {
				next({ name: "404" });
			}
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("./views/account/login"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					//console.log('RO1');
					// Redirect to the home page instead
					next({ name: "default", query: routeTo.query });
				} else {
					// Continue to the login page
					//console.log('RO2');
					next();
				}
			},
		},
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("./views/account/register"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/forgot-password",
		name: "Forgot password",
		component: () => import("./views/account/forgot-password"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				store.dispatch("clearData");
				//if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
				store.dispatch("auth/logOut");
				//store.dispatch('clearData')
				//} else {
				//  store.dispatch('authfack/logout')
				//}
				//next('/login')

				//this.$router.push({path: '/login'})
				//next('/login?redirectFrom=%2Flogout');
				//next({name: 'default'});
				next({ name: "login" });
				//next('/profile')
				//const authRequiredOnPreviousRoute = routeFrom.matched.some(
				//  (route) => route.push('/')
				//)
				// Navigate back to previous page, or home as a fallback
				//next(authRequiredOnPreviousRoute ? { name: 'default' } : { ...routeFrom })
			},
		},
	},
	{
		path: "/404",
		name: "404",
		component: () => import("./views/utility/404"),
	},
	// Redirect any unmatched routes to the 404 page. This may
	// require some server configuration to work in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	{
		path: "*",
		redirect: "404",
	},
];
