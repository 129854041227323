import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
//import * as VueGoogleMaps from "vue2-google-maps";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import vco from "v-click-outside";
import router from "./router/index";
import Scrollspy from "vue2-scrollspy";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotifications from "vue-notifications";
import lodash from "lodash";
import toastr from "toastr"; // https://github.com/CodeSeven/toastr
import moment from "moment";
import "toastr/build/toastr.min.css";

import "@/design/index.scss";

import store from "@/state/store";

import App from "./App.vue";

import { initFirebaseBackend } from "./fbUtils";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { firestorePlugin } from "vuefire";

import i18n from "./i18n";

import { configureFakeBackend } from "./helpers/fake-backend";

import tinymce from "vue-tinymce-editor";

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "./helpers/leafletHelpers"; // Import and execute the fix

Vue.component("tinymce", tinymce);
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.prototype._ = lodash;

// eslint-disable-next-line no-unused-vars
function toast({ title, message, type, timeout, cb }) {
	if (type === VueNotifications.types.warn) type = "warning";
	return toastr[type](message, title, { timeOut: timeout }); //, positionClass: "toast-bottom-center"})
}

const options = {
	success: toast,
	error: toast,
	info: toast,
	warn: toast,
};

const firebaseConfig = {
	apiKey: process.env.VUE_APP_APIKEY,
	authDomain: process.env.VUE_APP_AUTHDOMAIN,
	databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
	projectId: process.env.VUE_APP_PROJECTId,
	storageBucket: process.env.VUE_APP_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_APPId,
	measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
	initFirebaseBackend(firebaseConfig);
} else {
	configureFakeBackend();
}

Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
//Vue.use(VueSweetalert2, {
//	confirmButtonColor: "#2F92A5",
//});
Vue.use(VueNotifications, options);
Vue.use(firestorePlugin);

Vue.component("apexchart", VueApexCharts);
Vue.prototype.$formatDate = function (input, format = "LLLL") {
	const locale = store.state.lang;
	moment.locale(locale);
	let date = input;

	if (!date) {
		return "";
	}

	if (date instanceof firebase.firestore.Timestamp) {
		date = date.toDate();
	} else if (date && (date.seconds || date.nanoseconds)) {
		date = new Date(date.seconds * 1000);
	} else if (Number.isNaN(Number(date))) {
		return ""; //new Date();
	}
	//return date;
	return moment(date).format(format);
};

new Vue({
	router,
	store,
	i18n,
	components: {
		App,
	},
	render: (h) => h(App),
}).$mount("#app");

/*
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
*/
